<template>
  <div class="d-row" v-if="settingsStore.getServicesLoaded">
    <div>
      <ServiceTable
        :data="settingsStore.getServices"
        @detail="
          (index) => {
            state.detail = settingsStore.getServices.find((a) => a.id == index);
          }
        "
      ></ServiceTable>
      <div class="buttons">
        <button class="accent" @click="() => vfm.open(serviceModalId)">
          <b>+</b>Create service
        </button>
      </div>
    </div>
    <div class="small-table">
      <ServiceDetail
        v-if="state.detail"
        :service="state.detail"
        @edit="() => vfm.open(editServiceModalId)"
        @delete="openDeleteServiceModal"
      >
      </ServiceDetail>
    </div>
  </div>
  <Loader v-else></Loader>
  <CreateServiceModal
    :modal-id="serviceModalId"
    @close="() => vfm.close(serviceModalId)"
  ></CreateServiceModal>
  <EditServiceModal
    :value="state.detail"
    :modal-id="editServiceModalId"
    @close="() => vfm.close(editServiceModalId)"
    @service="state.detail"
  >
  </EditServiceModal>
  <ConfirmModal
    :modal-id="confirmModalId"
    text="Do you realy want to delete this service?"
    confirm-text="Delete"
    @confirm="confirmDelete"
    @close="vfm.close(confirmModalId)"
  >
  </ConfirmModal>
</template>

<script setup lang="ts">
import ServiceTable from "@/components/tables/ServiceTable.vue";
import { useVfm } from "vue-final-modal";
import CreateServiceModal from "@/components/modals/CreateServiceModal.vue";
import EditServiceModal from "@/components/modals/EditServiceModal.vue";
import { onMounted, reactive } from "vue";
import { useSettingsStore } from "@/stores/setting";
import ServiceDetail from "@/components/services/ServiceDetail.vue";
import Loader from "@/components/util/Loader.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

const settingsStore = useSettingsStore();
const serviceModalId = Symbol("serviceModalId");
const editServiceModalId = Symbol("editServiceModalId");
const confirmModalId = Symbol("confirmModalId");
const vfm = useVfm();

const state = reactive({
  detail: null,
  deleteService: "",
});

onMounted(async () => {
  await settingsStore.loadServices();
});

function confirmDelete() {
  console.log("confirmDelete", state.deleteService);
  vfm.close(confirmModalId);
  deleteService();
}

function openDeleteServiceModal(serviceId: string) {
  state.deleteService = serviceId;
  console.log("openDeleteServiceModal", state.deleteService);
  vfm.open(confirmModalId);
}

async function deleteService() {
  console.log("deleteService", state.deleteService);
  await settingsStore.deleteService(state.deleteService);
  state.detail = null;
}
</script>

<style scoped lang="scss"></style>
